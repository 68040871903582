<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo$ | async">
    <ng-container gbc-page-header-actions>
      <button gbc-menu-item (click)="editFacility()" angulartics2On="click" angularticsAction="editFacilityShown" angularticsCategory="mixitInstallation">
        <img src="./assets/svgs/edit.svg" style="margin-right: 10px" />
        {{ 'mixit-facility-page.edit-facility' | translate }}
      </button>
      <button gbc-menu-item (click)="editInstallation()" angulartics2On="click" angularticsAction="editInstallationShown" angularticsCategory="mixitInstallation">
        <img src="./assets/svgs/edit.svg" style="margin-right: 10px" />
        {{ 'mixit-installation-page.edit-installation' | translate }}
      </button>
    </ng-container>
  </gbc-page-header>
  <gbc-page-container>
    <ng-container *ngIf="isMixitCooling$ | async; else showInstallation">
      <gbc-message-tile
        data-test-id="mixit-cooling-info-message"
        icon="./assets/svgs/info-circle_outline.svg"
        title="{{ 'mixit-installation-page.cooling-info-title' | translate }}"
        text="{{ 'mixit-installation-page.cooling-info-text' | translate }}">
      </gbc-message-tile>
    </ng-container>

    <ng-template #showInstallation>
      <app-mixit-premium-dashboard
        *ngIf="premium$ | async"
        [alarm$]="alarm$"
        [application$]="application$"
        [dataPoints$]="dataPoints$"
        [installation$]="installation$"
        [schematic$]="schematic$"
        data-test-id="mixit-premium-dashboard">
      </app-mixit-premium-dashboard>
      <app-mixit-freemium-dashboard
        *ngIf="(premium$ | async) === false"
        [alarm$]="alarm$"
        [application$]="application$"
        [dataPoints$]="dataPoints$"
        [installation$]="installation$"
        [schematic$]="schematic$"
        data-test-id="mixit-freemium-dashboard">
      </app-mixit-freemium-dashboard>
    </ng-template>
  </gbc-page-container>
</ng-template>
