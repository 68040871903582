// The names for the mixit datpoints are some that we have defined.
// This enum are the names that were given in the IntegrationTestMixitDataService.cs on the backend
// We try as much as possible to use the Dumbledore names, if there is none, we use the name that Mixit uses
export enum MixitDataPointName {
  // Meta stuff
  SystemState = 'mfdm:system_state',
  SwVersion = 'mfdm:sw_version',
  BleSwVersion = 'mfdm:ble_sw_version',

  /**
   * This value is based on the enum FlowDirection in the backed and can have these values when setting it in
   * integration tests:
   *
   * Left = 0
   * Right = 1
   * Up = 2
   * Down = 3
   *
   * We only ever get a DataPointValue which is a string like "Up"
   */
  AbPortOrientation = 'mfdm:ab_port_orientation',

  // Data points
  VolumeAverage = 'mfdm:VOLUME_HEATING',
  EnergyHeatingAverage = 'mfdm:ENERGY_HEATING',
  ThermalPowerFlowAverage = 'mfdm:thermal_power_flow_average',
  ThermalPowerFlowAverageCalibrated = 'mfdm:THERMAL_POWER_FLOW_AVERAGE',
  ValveSupplyFlowAverage = 'mfdm:valve_supply_flow_average',
  WeightedDeltaT = 'mfdm:DELTA_TEMPERATURE_AVERAGE',
  PumpPowerConsumptionAverage = 'mfdm:PUMP_POWER_CONSUMPTION_AVERAGE',

  // Mixit
  OperationMode = 'operation_mode',
  ApplicationType = 'mfdm:application_type',

  // Supply flow limiter
  SupplyFlowLimiterEnabled = 'mfdm:supply_flow_limiter_enabled',
  PrimaryFlowLimit = 'mfdm:primary_flow_limit',

  // Thermal Power limiter
  ThermalPowerLimiterEnabled = 'mfdm:thermal_power_limiter_enabled',
  ThermalPowerLimit = 'mfdm:thermal_power_limit',

  // Differential temperature limiter
  DifferentialTemperatureLimiterEnabled = 'mfdm:differential_temperature_limiter_enabled',
  DifferentialTemperatureLimit = 'mfdm:differential_temperature_limit',

  // Return temperature limiter
  ReturnTemperatureLimiterEnabled = 'mfdm:return_temperature_limiter_enabled',
  ReturnTemperatureLimitHeating = 'mfdm:return_temperature_limit_heating',
  ReturnTemperatureLimitCooling = 'mfdm:return_temperature_limit_cooling',

  // Warm Weather shutdown
  OutdoorTemperatureDependentOperationEnable = 'mfdm:outdoor_temperature_dependent_operation_enable', // Not sure about this one
  TemperatureThreshold = 'mfdm:temperature_threshold',
  AveragingPeriod = 'mfdm:averaging_period',

  // Underfloor protection
  FloorProtectInternal = 'mfdm:floor_protect_internal',
  FloorMaxTemperature = 'mfdm:floor_max_temperature',

  // Heat coil frost protection
  FrostProtectInternal = 'mfdm:frost_protect_internal',
  FrostReturnTempLimit = 'mfdm:frost_return_temp_limit',
  FrostZoneAirSupplyTempLimit = 'mfdm:frost_zone_air_supply_temp_limit',

  // Coil preheat
  CoilPreheatEnabled = 'mfdm:coil_preheat_enabled',
  CoilPreheatReturnTemp = 'mfdm:coil_preheat_return_temp',

  // Pump stuff
  PumpControlModeActual = 'mfdm:pump_control_mode_actual',
  PumpHead = 'mfdm:pump_head_average',
  PumpControlMode = 'mfdm:pump_control_mode',
  PumpHeadReference = 'mfdm:pump_head_reference',
  PumpFlowReference = 'mfdm:pump_flow_reference',
  PumpHeadDutyPoint = 'mfdm:pump_head_duty_point',
  PumpFlowDutyPoint = 'mfdm:pump_flow_duty_point',
  PumpSpeedReference = 'mfdm:pump_speed_reference',
  PumpControlledByMixitUnit = 'mfdm:pump_controlled_by_mixit_unit',
  PumpFlowAverage = 'mfdm:pump_flow_average',
  PumpType = 'pump_type',
  PumpLocation = 'pump_location',
  PumpCount = 'pump_count',

  // KVS
  LimitedCapacityEnabled = 'mfdm:limited_capacity_enabled',
  LimitedCapacity = 'mfdm:limited_capacity',

  // Manual valve opening
  OverrideEnabled = 'mfdm:override_opening_enabled',
  OverrideOpening = 'mfdm:override_opening',

  // Thermal power

  // Setpoint
  SetpointSource = 'mfdm:setpoint_source',
  LocalSetpoint = 'mfdm:local_setpoint',

  // Heat Curve (actual heat curve read from elsewhere)
  HeatcurveY0 = "heatcurve_y0",
  HeatcurveY1 = "heatcurve_y1",
  HeatcurveY2 = "heatcurve_y2",
  HeatcurveY3 = "heatcurve_y3",
  HeatcurveY4 = "heatcurve_y4",
  HeatcurveX0 = "heatcurve_x0",
  HeatcurveX1 = "heatcurve_x1",
  HeatcurveX2 = "heatcurve_x2",
  HeatcurveX3 = "heatcurve_x3",
  HeatcurveX4 = "heatcurve_x4",
  DeltaOffset = 'mfdm:delta_offset',
  SlopeFactor = 'mfdm:slope_factor',
  HeatCurve = 'mfdm:heat_curve',
  OutdoorTemperatureCompensationForcedLinear = 'mfdm:outdoor_temperature_compensation_forced_linear',
  OutdoorTemperatureCompensationEnabled = 'mfdm:outdoor_temperature_compensation_enabled',
  // Only used to set heat curve for integration tests
  ResultingHeatCurve = 'mfdm:resulting_heat_curve',

  // Not sure about these
  Enable = 'mfdm:enable',
  RequestedValveOpeningAverage = 'mfdm:requested_valve_opening_average',
  SupplyFlowTemperatureAverage = 'mfdm:supply_flow_temperature_average',
  ReturnTempEstimateAverage = 'mfdm:return_temp_estimate_average',
  OutdoorTemperatureAverage = 'mfdm:outdoor_temperature_average',
  PumpFlowTemperatureAverage = 'mfdm:pump_flow_temperature_average',
  ControlSource = 'mfdm:control_source',
  EffectiveTempSetPointAverage = 'mfdm:effective_temp_set_point_average',

  // Extended (i.e. not in the mixit dynamic profile)
  UpdateTime = 'update_time',
  DeviceTimestamp = 'device_timesstamp',
  DeviceId = 'device_id',
  DeltaTemp = 'delta_temp',
  Features = 'features'
}

export enum OperationMode {
  BalancingLimitersActive = 'BalancingLimitersActive',
  CoilPreheatActive = 'CoilPreheatActive',
  FloorOverheatProtectionActive = 'FloorOverheatProtectionActive',
  FrostProtectionActive = 'FrostProtectionActive',
  NormalTemperatureControl = 'NormalTemperatureControl',
  Scheduling = 'Scheduling',
  Unknown = '-',
  WarmWeatherShutdown = 'WarmWeatherShutdown'
}

export enum SetpointSource {
  MixingLoopSetpointSourceNone = 'MixingLoopSetpointSourceNone',
  MixingLoopSetpointSourceLocal = 'MixingLoopSetpointSourceLocal',
  MixingLoopSetpointSourceAnalogueInput = 'MixingLoopSetpointSourceAnalogueInput',
  MixingLoopSetpointSourceFieldbus = 'MixingLoopSetpointSourceFieldbus',
  MixingLoopSetpointSourceOutdoorTemperatureAnalogueInput = 'MixingLoopSetpointSourceOutdoorTemperatureAnalogueInput',
  MixingLoopSetpointSourceOutdoorTemperatureFieldbus = 'MixingLoopSetpointSourceOutdoorTemperatureFieldbus'
}

export enum UnitType {
  // DB values
  None = 'None',
  DegreeCelcius = 'DegreeCelcius',
  CubicMeterPerHour = 'CubicMeterPerHour',
  CubicMeter = 'CubicMeter',
  MWh = 'MWh',
  kWh = 'kWh',
  kW = 'kW',
  Bar = 'Bar',
  RPM = 'RPM',
  Meter = 'Meter',
  MeterHead = 'MeterHead',
  Hz = 'Hz',
  Percentage = 'Percentage',
  // Valus which need to converted
  Fraction = 'Fraction',
  W = 'W',
  mBar = 'mBar',
  DegreeKelvin = 'DegreeKelvin',
  CubicMeterPerSecond = 'CubicMeterPerSecond',
  Pa = 'Pa'
}

export enum LicenseType {
  Unknown = 'Unknown',
  Freemium = 'Freemium',
  CodeConnectTrial = 'CodeConnectTrial',
  CodeConnectUnlimited = 'CodeConnectUnlimited',
  CodeDynamicUnlimited = 'CodeDynamicUnlimited',
  CodeConnectUnlimitedPhysical = 'CodeConnectUnlimitedPhysical',
  CodeDynamicUnlimitedPhysical = 'CodeDynamicUnlimitedPhysical',
  CodeConnectSubscription = 'CodeConnectSubscription', // TODO
  CodeDynamicSubscription = 'CodeDynamicSubscription' // TODO
}

export const connectLicenseTypes = [
  LicenseType.CodeConnectTrial,
  LicenseType.CodeConnectUnlimited,
  LicenseType.CodeConnectUnlimitedPhysical,
  LicenseType.CodeConnectSubscription
];

export const dynamicLicenseTypes = [
  LicenseType.CodeDynamicUnlimited,
  LicenseType.CodeDynamicUnlimitedPhysical,
  LicenseType.CodeDynamicSubscription
];
