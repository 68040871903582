import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult } from '../../../interfaces/data-points';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Serie, SerieType } from '../temperature-chart-tile/temperature-chart-tile.component';
import { EEquipment } from '@ams/dumbledore';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-temperatures-chart',
  templateUrl: './mixit-temperatures-chart.component.html',
  styleUrls: ['./mixit-temperatures-chart.component.scss'],
})
export class MixitTemperaturesChartComponent implements OnInit {
  @Input() public title: string;
  @Input() public applicationId: string;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public series$: Observable<Serie[]>;
  public colors = [
    '#DD2028', // Primary forward
    '#0068B4', // Primary return
    '#009EE3', // Outdoor
    '#59B093', // Setpoint
    '#CCC82D', // DeltaT
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    // Change flow to supply
    // Change setpoint to flow

    const supplyTemperatureLegend$ = this.translateService.get('mixit-temperatures-chart.supply-temperature');
    const returnLegend$ = this.translateService.get('forward-return-chart-tile.return-legend');
    const outDoorLegend$ = this.translateService.get('mixit-temperatures-chart.outdoor-temp');
    const flowTemperatureLegend$ = this.translateService.get('forward-return-chart-tile.flow-legend');
    const deltaTLegend$ = this.translateService.get('mixit-temperatures-chart.delta-t-primary');

    this.series$ = combineLatest([supplyTemperatureLegend$, returnLegend$, outDoorLegend$, flowTemperatureLegend$, deltaTLegend$]).pipe(
      map(([supplyTemperatureLegend, returnLegend, outdoorLegend, flowTemperatureLegend, deltaTLegend]) => {
        const series: Serie[] = [
          {
            type: SerieType.DataPoint,
            title: supplyTemperatureLegend,
            humanReadableId: MixitDataPointName.SupplyFlowTemperatureAverage,
            systemType: SystemDeviceType.MixitSystem,
            dumbledoreId: EEquipment.T_PF,
          },
          {
            type: SerieType.DataPoint,
            title: returnLegend,
            humanReadableId: MixitDataPointName.ReturnTempEstimateAverage,
            systemType: SystemDeviceType.MixitSystem,
            dumbledoreId: EEquipment.T_PR,
          },
          {
            type: SerieType.DataPoint,
            title: outdoorLegend,
            humanReadableId: MixitDataPointName.OutdoorTemperatureAverage,
            systemType: SystemDeviceType.MixitSystem,
            dumbledoreId: EEquipment.MIXIT_OUTDOOR,
          },
          {
            type: SerieType.DataPoint,
            title: flowTemperatureLegend,
            dumbledoreId: EEquipment.T_SF,
            humanReadableId: MixitDataPointName.PumpFlowTemperatureAverage,
            systemType: SystemDeviceType.MixitSystem,
          },
          {
            type: SerieType.DeltaT,
            title: deltaTLegend,
            forwardDumbledoreId: EEquipment.T_PF,
            returnDumbledoreId: EEquipment.T_PR,
          },
        ];
        return series;
      })
    );
  }
}
